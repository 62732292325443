export default class CustomFile {
  constructor() {
    this.setInitialValue();
  }
  setInitialValue() {
    this.ext = "";
    this.file = "";
    this.mediaTypeToken = "";
    this.size = "";
    this.sizeText = "";
    this.type = "";
  }
}
